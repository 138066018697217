import * as types from '../../actions/types';

const initialState = {
  list: [],
  current: null,
  loading: false
};

export default function questions (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_EDITOR_QESTIONS_LIST:
      return {
        ...state,
        list: action.payload
      };
    
    case types.SET_EDITOR_QESTIONS_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    
    case types.SET_EDITOR_QESTIONS_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    default:
      return state;
  }
}
