import { persistStore } from 'redux-persist'
import { PersistGate } from "redux-persist/lib/integration/react";
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import Routes from './routing';
import reducer from './reducers';
import { NotificationContainer } from 'react-notifications';
import { configureApi } from './api';


import 'react-notifications/lib/notifications.css'
import 'react-responsive-modal/styles.css';
import 'react-quill/dist/quill.snow.css';




const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

const persistor = persistStore(store);

export const api = configureApi(store)

function App () {


  return (
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Routes />
          <NotificationContainer/>
        </Provider>
      </PersistGate>
  );
}

export default App;
