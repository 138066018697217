import React from "react";
import styles from "./index.module.css"

const Index = ({
    label,
    value,
    color,
    fontWeight,
    fontSize,
    fontStyle,
    title,
    ...props
  }) => {
  
  const colorStyles = color ? {
    color: color,
    borderColor: color
  } : null

  const fontStyles = {
    fontWeight: fontWeight,
    fontSize: fontSize,
    fontStyle: fontStyle
  }

  return (
    <div title={title} className={styles.wrapper}>
      <label className={styles.checkbox}>
        <input type="checkbox" checked={value} {...props} />
        <div>
          <i style={colorStyles} className={value ? styles.fakeBoxActive : styles.fakeBox}/>
        </div>
        <span className={styles.span} style={fontStyles}>{label}</span>
      </label>
    </div>
  );
};

export default Index;
