import React from 'react'
import styles from './styles.module.scss'
import { useFormik } from 'formik'
import Input from '../../components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../actions/auth'
import { Navigate, useLocation } from 'react-router-dom'



const Auth = () => {

  const dispatch = useDispatch()
  const { access_token } = useSelector(state => state.auth)
  const {state} = useLocation()

  const {
    values, 
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => dispatch(auth(values))
  })

  if (access_token) return <Navigate to={state || '/test_editor'} replace/>

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder='Логин'
          value={values.email}
          onChange={handleChange}
          name='email'
        />
        <Input
          placeholder='Пароль'
          value={values.password}
          onChange={handleChange}
          name='password'
          type='password'
        />
        <button type='submit'>Войти</button>
      </form>
    </div>
  )
}

export default Auth