import React from 'react'
import styles from './styles.module.scss'
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import { MdOutlineWatchLater } from "react-icons/md";


const Contacts = () => {
  
  
  
  return (
    <div className={styles.wrapper}>
      <h2>Контактная информация</h2>
      <p>107076, Москва, Преображенская пл., д. 7а, стр. 1, офис 217</p>
      <div>
        <FaPhoneAlt/>
        <p>+7 (495) 780-76-71</p>
      </div>
      <div>
        <FaEnvelope/>
        <p>info@nintegra.ru</p>
      </div>
      <div>
        <MdOutlineWatchLater/>
        <p>Пн.-Пт. 9:30-18:30</p>
      </div>
    </div>
  )
}

export default Contacts