import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_EDITOR_CURRENT_TEST, SET_EDITOR_LOADING, SET_EDITOR_QESTIONS_CURRENT, SET_EDITOR_QESTIONS_LOADING, SET_EDITOR_TEST_LIST } from "./types";




export const getTestsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_LOADING, payload: true });
    const { data: payload } = await api.get('/tests')
    dispatch({ type: SET_EDITOR_TEST_LIST, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_LOADING, payload: false });
  }
};

export const getCurrentTest = (testId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_LOADING, payload: true });
    const { data: payload } = await api.get(`/tests/${testId}/questions_answers`)
    dispatch({ type: SET_EDITOR_CURRENT_TEST, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_LOADING, payload: false });
  }
};

export const createTest = (data) => async (dispatch, getState) => {
  let testId
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    const response = await api.post('/tests', data)
    testId = response.data.id
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
    return testId
  }
};

export const updateTest = (id, data) => async (dispatch, getState) => {
  let returnState
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    await api.put(`/tests/${id}`, data)
    await dispatch(getCurrentTest(id))
    returnState = true
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
    return returnState
  }
}

export const deleteTest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    await api.delete(`/tests/${id}`)
    await dispatch(getTestsList())
    createNotification('success', 'Тест удален')
  } catch ({response}) {
    createNotification('error', response.data.message)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
  }
}

export const getCurrentQuestion = (questionId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    const { data: payload } = await api.get(`/questions/${questionId}`)
    dispatch({ type: SET_EDITOR_QESTIONS_CURRENT, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
  }
}

export const createQuestion = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    await api.post('/questions/answers', data)
    await dispatch(getCurrentTest(data.test_id))
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
  }
};

export const updateQuestion = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    await api.put(`/questions/${id}/answers`, data)
    await dispatch(getCurrentTest(getState().editor.current.id))
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
  }
}

export const deleteQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: true });
    await api.delete(`/questions/${id}/answers`)
    await dispatch(getCurrentTest(getState().editor.current.id))
  } catch ({response}) {
    createNotification('error', response.data.message)
  } finally {
    dispatch({ type: SET_EDITOR_QESTIONS_LOADING, payload: false });
  }
}