import { useEffect } from 'react'
import { useFormik } from 'formik'
import Modal from 'react-responsive-modal'
import styles from './styles.module.scss'

import Input from '../Input'
import Textarea from '../Textarea'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { createTest, updateTest } from '../../actions/editor'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill'


const INITIAL_VALUES = { title: '', description: '' }


const AddEditTestModal = ({open, closeModal, addMode, data}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])
  
  const createTestAction = async (values) => {
    const testId = await dispatch(createTest(values))
    testId && navigate(`/test_editor/${testId}`)
  }
  
  const updateTestAction = async ({id, questions, ...values}) => {
    const success = await dispatch(updateTest(id, values))
    success && closeModal()
  }

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: addMode ? createTestAction : updateTestAction
  })
  
  const testTitle = addMode ? 'Создание теста' : 'Редактирование теста'
  
  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      center
    >
        <form onSubmit={handleSubmit}>
          <h2>{testTitle}</h2>
          <div>
            <p>Название</p>
            <ReactQuill className={styles.quill} value={values.title} onChange={value => setFieldValue('title', value)} />
          </div>
          <Textarea
            placeholder='Описание'
            className={styles.textarea}
            value={values.description}
            onChange={handleChange}
            name='description'
          />
          <div className={styles.buttonsBlock}>
            <Button text='Отмена' mode='danger' onClick={closeModal} type='button'/>
            <Button text='Сохранить' type='submit'/>
          </div>
        </form>
    </Modal>
  )
}


export default AddEditTestModal