import React from "react";
import { BrowserRouter, Navigate, Route, Routes as Switch} from "react-router-dom";

import { PrivateRoute } from "../components/Routing";
import Layout from "../components/Layout";
import Main from "../pages/Main";
import EditorList from "../pages/EditorList";
import TestEdition from "../pages/TestEdition";
import TestsList from "../pages/TestsList";
import Test from "../pages/Test";
import Contacts from "../pages/Contacts";
import Auth from "../pages/Auth";





export default function Routes () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='' element={<Layout/>}>
          
          <Route index element={<TestsList/>}/>
          <Route path='auth' element={<Auth/>}/>
          <Route path='contacts' element={<Contacts/>}/>
          
          <Route path='tests' >
            <Route index element={<TestsList/>}/>
            <Route path=':testId' element={<Test/>}/>
          </Route>

          <Route element={<PrivateRoute/>}>
            <Route path='test_editor'>
              <Route index element={<EditorList/>}/>
              <Route path=':testId' element={<TestEdition/>}/>
            </Route>
          </Route>

          <Route path='*' element={<Main/>}/>
        </Route>
      </Switch>  
    </BrowserRouter>
  )
}