import React from 'react'
import styles from './index.module.css'
import cn from 'classnames'


const Index = ({
    maxLength = 'auto',
    isValid = true,
    errorMessage = 'Некорректное значение',
    style,
    className,
    ...props
}) => {

    return (
        <div className={cn([className, styles.wrapper])} style={style}>
            <span className={styles.placeholder}>{props.placeholder}
            {!isValid && <span className={styles.error}>{errorMessage}</span>}
            </span>
            <input 
                className={styles.input} 
                maxlength={maxLength}
                autoFocus={false}
                // autoComplete="new-password"
                {...props}
            />
        </div>
    )
}

export default Index