import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { createQuestion, getCurrentQuestion, updateQuestion } from '../../actions/editor'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { QuestionContext } from '../../utils/context'
import { ChoiceQuestion, MultipleQuestion } from './Components'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'


const QUESTION_TYPES = [
  { value: 'single', name: '1 правильный ответ'},
  { value: 'multiple', name: 'несколько ответов'},
]

export const INITIAL_QUESTION_DATA = {
  single: {
    question_type: 'single',
    question_text: 'Текст вопроса',
    description: '',
    answers: [
      {
        answer_text: 'Вариант 1',
        is_correct: true
      },
      {
        answer_text: 'Вариант 2',
        is_correct: false
      },
      {
        answer_text: 'Вариант 3',
        is_correct: false
      },
    ]
  },
  multiple: {
    question_type: 'multiple',
    question_text: 'Текст вопроса',
    description: '',
    answers: [
      {
        answer_text: 'Вариант 1',
        is_correct: true
      },
      {
        answer_text: 'Вариант 2',
        is_correct: false
      },
      {
        answer_text: 'Вариант 3',
        is_correct: false
      },
    ]
  }
}


const QuestionModal = ({open, closeModal, questionId, addMode, testId}) => {
  
  const dispatch = useDispatch()
  const { current: data } = useSelector(state => state.editor.questions)

  useEffect(() => {
    if (!open) return;
    if (addMode) {
      setValues(INITIAL_QUESTION_DATA.single)
    } else {
      dispatch(getCurrentQuestion(questionId))
    }
  }, [open])
  
  useEffect(() => {
    data && setValues(data)
  }, [data])
  

  const createQuestionHandler = async (data) => {
    const payload = {...data, test_id: testId}
    await dispatch(createQuestion(payload))
    closeModal()
  }

  const updateQuestionHandler = async ({id, ...data}) => {
    await dispatch(updateQuestion(id, data))
    closeModal()
  }

  const questionForm = useFormik({
    initialValues: INITIAL_QUESTION_DATA.single,
    onSubmit: addMode ? createQuestionHandler : updateQuestionHandler
  })

  const { values, setValues, handleSubmit } = questionForm

  const selectTypeHandler = (type) => () => {
    const values = INITIAL_QUESTION_DATA[type] 
    setValues(addMode ? values : { ...values, id: questionId })
  }

  const renderType = ({name, value}) => <Checkbox label={name} value={value===values.question_type} onChange={selectTypeHandler(value)}/>

  return (
    <QuestionContext.Provider value={questionForm}>
      <Modal
        open={open}
        onClose={closeModal}
        classNames={{ modal: styles.modal }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >

        <h1>{!!values.id ? 'Редактирование вопроса' : 'Добавление вопроса'}</h1>

        <div className={styles.typesBlock}>
          <h3>Выберите тип вопроса</h3>
          <div>{QUESTION_TYPES?.map(renderType)}</div>
        </div>

        {values.question_type === 'single' ? <ChoiceQuestion /> : <MultipleQuestion />}

        <div className={styles.buttonsBlock}>
          <Button text={addMode ? 'Добавить' : 'Сохранить'} onClick={handleSubmit}/>
          <Button text='Закрыть' onClick={closeModal} mode='danger' />
        </div>

      </Modal>
    </QuestionContext.Provider>
  )
}

export default QuestionModal