import axios from 'axios'
import { BACKEND_URL_PREFIX } from '../assets/const'
import { createNotification } from '../components/Notifications/Notifications'
import { logOut } from '../actions/auth'


export const configureApi = store => {
  
  const instance = axios.create({
    baseURL: BACKEND_URL_PREFIX
  })

  instance.interceptors.request.use(config => {
    const {auth, tests} = store.getState();
    const token = tests.token || auth.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        store.dispatch(logOut())
      } else if (error.response?.status === 503) {
        createNotification('error', `Сервис временно недоступен`)
      }
      return Promise.reject(error)
    }
  )

  return instance
}