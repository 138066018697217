import React, { useContext, useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { TestContext } from '../../../utils/context'
import Loader from 'react-loader';
import Button from '../../../components/Button'
import { useDispatch } from 'react-redux';


const FinalWindow = () => {
  
  const dispatch = useDispatch()
  const {closeTestHandler, getCertificate, statistic, testId} = useContext(TestContext)

  useEffect(() => {
    getStatisticAction()
  }, [])

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)

  const getStatisticAction = async () => {
    setLoading(true)
    const certificate = await dispatch(getCertificate(testId))
    certificate && setFile(certificate)
    setLoading(false)
  }
  
  function loadCertificateHandler() {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'certificate');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  return (
    <div>
      <div className={styles.finalWindow}>
        {loading && <Loader/>}
        {!loading && 
          <>
            <h1>{file ? 'Тест пройден' : 'Тест не пройден'}</h1>
            {file && <button onClick={loadCertificateHandler}>Получить сертификат</button>}
          </>
        } 
      </div>
      <div className={styles.buttonsBlock}>
        <Button onClick={closeTestHandler} text='Завершить' mode='danger'/>
      </div>
    </div>
  )
}

export default FinalWindow