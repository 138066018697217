import React from 'react'
import Button from '../Button'
import styles from './Confirmation.module.css'
import classNames from 'classnames'


export default function Confirmation({
    children,
    open,
    acceptConfirmation = () => {},
    rejectConfirmation = () => {}    
}) {

  const confirmClassNames = classNames(styles.absolute, {[styles.visible]: open})

  return (
    <div className={confirmClassNames} onClick={rejectConfirmation}>
      <div onClick={e => e.stopPropagation()} className={styles.confirmation}>
      <div className={styles.textBlock}>
        {children}
      </div>
      <div className={styles.buttons}>
        <Button text='Да' onClick={acceptConfirmation}/>
        <Button text='Нет' onClick={rejectConfirmation} mode='danger'/>
        </div> 
    </div>
    </div>
  )
}
