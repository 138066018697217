import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate, useLocation } from 'react-router-dom'


function PrivateRoute() {
  
  const { access_token: isAuth } = useSelector(state => state.auth)
  
  const {pathname} = useLocation()


  return isAuth ? <Outlet/> : <Navigate to='/auth' state={pathname} replace/>
}


export { PrivateRoute }