import * as types from '../actions/types';

const initialState = {}

export default function auth (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_AUTH_DATA:
      return {
        ...state,
        ...action.payload
      }
    
    case types.LOG_OUT:
      return initialState

    case types.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload
      }
      
    default:
      return state;
  }
}
