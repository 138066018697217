import { combineReducers } from "redux";
import list from "./list";
import current from "./current";
import questions from "./questions";
import loading from "./loading";



const editor = combineReducers({
  list,
  current,
  questions,
  loading
})


export default editor