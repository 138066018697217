import React from 'react'
import styles from './index.module.css'

const Index = ({
    text = 'Сохранить',
    mode = 'primary',
    mr = '.5rem',
    ...props
}) => {

    const typeButton = styles[mode]
    
    return (
        <button
            className={typeButton + ' ' + styles.button}
            style={{margin: mr}}
            {...props} 
        >
            {text}
        </button>
    )
}

export default Index