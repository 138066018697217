import React, { useContext, useEffect, useState } from 'react'
import { TestContext } from '../../../utils/context'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import styles from '../styles.module.scss'
import Button from '../../../components/Button'
import { useDispatch } from 'react-redux'
import { shuffle } from '../../../utils/smallHelpers'


const MultichoiceQuestion = ({visible, question, navPanel, setQuestionPassed}) => {
  
  const dispatch = useDispatch()

  const {addQuestionAnswer, nextQuestion, closeTestHandler, testId} = useContext(TestContext)
  
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    const withCorrectField = question.answers.map(answer => ({...answer, is_correct: false}))
    const answers = shuffle(withCorrectField)
    setAnswers(answers)
  }, [])

  const renderAnswer = (answer, index) => <Checkbox onClick={selectAnswerHandler(index)} label={answer.answer_text} value={answer.is_correct}/>
  
  const sendAnswerHandler = async () => {
    const payload = answers.filter(({is_correct}) => !!is_correct).map((answer) => ({
      question_id: question.id,
      answer_id: answer.id,
    }))
    const success = await dispatch(addQuestionAnswer(payload))
    if (!success) return;
    nextQuestion()
    setQuestionPassed()
  }


  const selectAnswerHandler = (index) => () => {
    const newAnswers = JSON.parse(JSON.stringify(answers))
    newAnswers[index].is_correct = !newAnswers[index].is_correct
    setAnswers(newAnswers)
  }


  return (
    <div style={{display: visible ? 'block' : 'none'}}>
      <div className={styles.multichoiceQuestion}>
        <p className={styles.taskTitle}>{question.question_text}</p>
        <div>{answers.map(renderAnswer)}</div>
      </div>
      {navPanel}
      <div className={styles.buttonsBlock}>
        <Button onClick={closeTestHandler} text='Завершить' mode='danger'/>
        <Button onClick={sendAnswerHandler} text='Ответить'/>
      </div>
    </div>
  )
}

export default MultichoiceQuestion