import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTestsList } from '../../actions/tests'
import styles from './styles.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import ReactLoader from 'react-loader'
import * as images from '../../assets/img'
import parse from 'html-react-parser'


const TestsList = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.tests)


  useEffect(() => {
    dispatch(getTestsList())
  }, [])
  
  const renderTest = (test, ind) => {
    const imageKey = `img${ind%6+1}`
    const backgroundImage = `url(${images[imageKey]})`
    return (
      <li key={test.id} style={{backgroundImage}}>
        <Link to={`/tests/${test.id}`}>
            <img src={images.img7} alt='o_O' />
            <div>{parse(test.title)}</div>
        </Link>
      </li>
    )
  }
  
  return (
    <ReactLoader loaded={!loading}>
      <div className={styles.wrapper}>
        <div>
          <h1>Система тестирования пользователей</h1>
          <h1>СПО "Образование-МО"</h1>
        </div>
        <ul className={styles.list}>{list?.map(renderTest)}</ul>
      </div>
    </ReactLoader>
  )
}

export default TestsList