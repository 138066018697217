import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage';
import editor from './editor';
import tests from './tests';
import auth from './auth';


const storageConfig = { whitelist: 'auth', key: 'certification_storage', storage };


export default persistCombineReducers(storageConfig, {auth, editor, tests})