import Modal from 'react-responsive-modal'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useFormik } from 'formik'
import styles from './styles.module.scss'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getToken } from '../../actions/tests'
import * as Yup from 'yup'



const INITIAL_VALUES = {firstName: '', lastName: '', fathersName: ''}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Обязательное поле'),
  lastName: Yup.string().required('Обязательное поле'),
  fathersName: Yup.string().required('Обязательное поле')
})


const PersonalInfoModal = ({open, closeModal, setUserInfo}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    handleReset()
  }, [open])
  

  const submitForm = async ({firstName, lastName, fathersName}) => {
    const fioString = `${lastName} ${firstName} ${fathersName}`
    const token = await dispatch(getToken(fioString))
    token && setUserInfo(token)
  }

  const {
    values,
    touched,
    errors, 
    handleChange,
    handleReset,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: submitForm
  })
  
  const error = (fieldName) => touched[fieldName] && errors[fieldName]
  
  return (
    <Modal
      open={open}
      classNames={{modal: styles.fioModal}}
      showCloseIcon={false}
      onClose={closeModal}
      center
    >
      <form onSubmit={handleSubmit}>
        <h1>Введите данные</h1>
        <Input
          name='lastName'
          placeholder='Фамилия'
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{margin: '.5rem 0'}}
          errorMessage={error('lastName')}
          isValid={touched.lastName && !error('lastName')}
          />
        <Input
          name='firstName'
          placeholder='Имя'
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{margin: '.5rem 0'}}
          errorMessage={error('firstName')}
          isValid={touched.firstName && !error('firstName')}
          />
        <Input
          name='fathersName'
          placeholder='Отчество'
          value={values.fathersName}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{margin: '.5rem 0'}}
          errorMessage={error('fathersName')}
          isValid={touched.fathersName && !error('fathersName')}
        />
        <div className={styles.buttonsBlock}>
          <Button text='Отмена' mode='danger' onClick={closeModal} type='button'/>
          <Button text='Подтвердить' type='submit'/>
        </div>
      </form>
    </Modal>
  )
}


export default PersonalInfoModal