import React from 'react'
import styles from './styles.module.scss'


export default function Main () {
  return (
    <div className={styles.wrapper}>
      <img src='/Slider-Путин-без-текста.jpg' alt='putin'/>
        <div>
          <p>
            Система высшего военного образования, подготовка офицерских кадров является,
            безусловно, фундаментом Вооружённых Сил. И он должен быть надёжным, прочным,
            отвечающим требованиям времени и рассчитанным, что очень важно, на перспективу.
          </p>
          <p>
            В.В.Путин
            <br/>
            Президент Российской Федерации
          </p>
        </div>
    </div>
  )
}
