import * as types from '../../actions/types';

const initialState = []

export default function list (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_EDITOR_TEST_LIST:
      return action.payload
      
    default:
      return state;
  }
}
