import { api } from "../App"
import { createNotification } from "../components/Notifications/Notifications"
import { LOG_OUT, SET_AUTH_DATA, SET_AUTH_LOADING } from "./types"




export const auth = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true })
    const { data } = await api.post('/admin/login', values)
    dispatch({ type: SET_AUTH_DATA, payload: data })
  } catch ({status}) {
    const errorText = status === 400 ? 'Неверные имя пользователя или пароль' : 'Неизвестная ошибка'
    createNotification('error', errorText)

  } finally {
    dispatch({ type: SET_AUTH_LOADING, payload: false })
  }
}


export const logOut = () => ({ type: LOG_OUT })