import * as types from '../../actions/types';

const initialState = null

export default function current (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_EDITOR_CURRENT_TEST:
      return action.payload

    default:
      return state;
  }
}
